@import "../../../styles/variables";

.upload-form--disabled {
    opacity: 0.5;
    pointer-events: none;
}

.upload-form {
    min-width: 380px;

    .upload-form__file {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: baseline;
        margin: 0 0 $ds-spacing-x-small 0;
        padding: 0;
        border: none;

        .upload-form__document {
            margin-bottom: $ds-spacing-x-small;
            .document__label {
                color: $ds-color--black;
                font-size: $ds-font-size--base;
            }
        }

        .upload-form__type {
            width: 100%;
        }
    }
    .upload-form__controls {
        display: flex;
        justify-content: flex-end;
        padding-top: $ds-spacing-x-small;
        button {
            margin-left: $ds-spacing-x-small;
        }
    }
}

.uploading-status-indicator {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-top: $ds-spacing-x-small;
    text-align: right;

    & > span {
        font-weight: bold;
        color: $ds-color-utility--green;
        padding-left: $ds-spacing-large;
    }
}

@media only screen and (min-width: 1535px) {
    .upload-form {
        min-width: 500px;
    }
}
