// Default Spacing
$ds-spacing-xxx-large: 4.8rem;
$ds-spacing-xx-large: 6.4rem;
$ds-spacing-x-large: 2.4em;
$ds-spacing-large: 2rem;
$ds-spacing-base: 1.6rem;
$ds-spacing-small: 1.2rem;
$ds-spacing-x-small: 0.8rem;
$ds-spacing-xx-small: 0.4rem;
$ds-spacing-xxx-small: 0.2rem;
$ds-spacing-none: 0;

// Button Spacing
$ds-button-margin--bottom: $ds-spacing-small;
$ds-button-margin--left: $ds-spacing-x-small;

// Form Element Spacing
$ds-form-input-lookup--list-item-padding: $ds-spacing-small;
