@import "../../../../../styles/variables";

.dl-table {
    position: relative;
    border-spacing: 0;
    border-collapse: collapse;
    width: calc(100% - 4rem);
    thead {
        border-bottom: none;
    }
    th {
        position: sticky;
        border-collapse: collapse;
        top: 0;
        margin: 0;
        background-color: white;
        vertical-align: bottom;
        box-shadow: inset 0 -1px 0 $ds-color--gray__lighter;
    }

    tr:focus {
        outline: none;
        background-color: $ds-color--gray__xx-light;
    }

    tr.selected {
        color: $ds-color--blue;
        font-weight: bold;
        background-color: $ds-color--gray__xx-light;
    }

    td {
        vertical-align: top;
    }
}

@media screen and (min-width: 1280px) {
    .dl-table-col--date {
        text-align: right;
    }

    .dl-table-header-col--date,
    .dl-table-col--date {
        width: 165px;
    }
}
