/* 
BUTTON <button>
*/

.ds-button {
    @include typo--roboto__bold;
    line-height: $ds-line-height;
    letter-spacing: 0.025rem;
    outline: none;
    border-style: solid;
    border-radius: $ds-border-radius-medium;
    box-shadow: $ds-box-shadow--gray__native;
    box-sizing: border-box;
    appearance: none;

    &:hover,
    &:focus {
        box-shadow: $ds-box-shadow--gray__hover-focus;
    }

    &:active {
        box-shadow: $ds-box-shadow--gray__active;
    }
}

.ds-button::first-letter {
    text-transform: uppercase;
}

/* CATEGORY */
@mixin btnCategory($text, $background, $border) {
    background-color: $background;
    color: $text;
    border-color: $border;
    transition: $ds-motion-selection;
}

/* Primary */
.ds-button--cat__primary {
    @include btnCategory(
        $ds-color--white,
        $ds-color--blue__dark,
        $ds-color--blue__dark
    );

    &:hover,
    &:focus {
        @include btnCategory(
            $ds-color--white,
            $ds-color--blue,
            $ds-color--blue
        );
    }

    &:active {
        @include btnCategory(
            $ds-color--white,
            $ds-color--blue__dark,
            $ds-color--blue__dark
        );
    }
}

/* Secondary */
.ds-button--cat__secondary {
    @include btnCategory($ds-color--white, $ds-color--blue, $ds-color--blue);

    &:hover,
    &:focus {
        @include btnCategory(
            $ds-color--white,
            $ds-color--blue__light,
            $ds-color--blue__light
        );
    }

    &:active {
        @include btnCategory(
            $ds-color--white,
            $ds-color--blue,
            $ds-color--blue
        );
    }
}

/* Warning */
.ds-button--cat__warning {
    @include btnCategory(
        $ds-color--black,
        $ds-color-utility--yellow__dark,
        $ds-color-utility--yellow__dark
    );

    &:hover,
    &:focus {
        @include btnCategory(
            $ds-color--black,
            $ds-color-utility--yellow,
            $ds-color-utility--yellow
        );
    }

    &:active {
        @include btnCategory(
            $ds-color--black,
            $ds-color-utility--yellow__dark,
            $ds-color-utility--yellow__dark
        );
    }
}

/* Destructive */
.ds-button--cat__destructive {
    @include btnCategory(
        $ds-color-utility--red__dark,
        $ds-color--white,
        $ds-color--gray__lighter
    );

    &:hover,
    &:focus {
        @include btnCategory(
            $ds-color--white,
            $ds-color-utility--red,
            $ds-color-utility--red__dark
        );
    }

    &:active {
        @include btnCategory(
            $ds-color--black,
            $ds-color-utility--red__dark,
            $ds-color-utility--red__dark
        );
    }
}

/* Success */
.ds-button--cat__success {
    @include btnCategory(
        $ds-color--white,
        $ds-color-utility--green__dark,
        $ds-color-utility--green__dark
    );

    &:hover,
    &:focus {
        @include btnCategory(
            $ds-color--white,
            $ds-color-utility--green,
            $ds-color-utility--green
        );
    }

    &:active {
        @include btnCategory(
            $ds-color--white,
            $ds-color-utility--green__dark,
            $ds-color-utility--green__dark
        );
    }
}

/* Cancel */
.ds-button--cat__cancel {
    @include btnCategory(
        $ds-color--black,
        $ds-color--gray__lighter,
        $ds-color--gray__lighter
    );

    &:hover,
    &:focus {
        @include btnCategory(
            $ds-color--white,
            $ds-color--gray,
            $ds-color--gray
        );
    }

    &:active {
        @include btnCategory(
            $ds-color--white,
            $ds-color--gray__lighter,
            $ds-color--gray__lighter
        );
    }
}

/* Submit?? */
.ds-button--cat__submit {
    @include btnCategory(
        $ds-color--white,
        $ds-color--blue__dark,
        $ds-color--blue__dark
    );

    &:hover,
    &:focus {
        @include btnCategory(
            $ds-color--white,
            $ds-color--blue,
            $ds-color--blue
        );
    }

    &:active {
        @include btnCategory(
            $ds-color--white,
            $ds-color--blue__dark,
            $ds-color--blue__dark
        );
    }
}

/* Disabled */
.ds-button--cat__disabled {
    @include btnCategory(
        $ds-color--gray,
        $ds-color--gray__lighter,
        $ds-color--gray__lighter
    );
    box-shadow: none;
    cursor: not-allowed;

    &:active,
    &:hover,
    &:focus {
        box-shadow: none;
    }
}

/* SIZE */

@mixin btnSize($fontSize) {
    font-size: $fontSize;
    padding: $fontSize * 0.286 $fontSize * 1;
    border-width: $ds-border-width-medium;
}

/* Large */
.ds-button--size__large {
    @include btnSize($ds-font-size--larger);
}

/* Medium */
.ds-button--size__medium {
    @include btnSize($ds-font-size--base);
}

/* Small */
.ds-button--size__small {
    @include btnSize($ds-font-size--small);
}

/* Smallest */
.ds-button--size__smallest {
    @include btnSize($ds-font-size--small);
}

/* SHAPE */

@mixin btnShape($borderRadius) {
    border-radius: $borderRadius;
}

/* Default */
.ds-button--shape__default {
    @include btnShape(0.35rem);
}

.ds-button--shape__rounded {
    @include btnShape(50%);
}

/* BACKGROUND */

@mixin btnBackround($text, $background, $border) {
    background-color: $background;
    color: $text;
    border-color: $border;
    transition: $ds-motion-selection;
}

/* Seconday Ghost */
.ds-button--cat__secondary.ds-button--background__ghost {
    @include btnBackround($ds-color--blue, $ds-color--white, $ds-color--blue);

    &:hover,
    &:focus {
        @include btnBackround(
            $ds-color--white,
            $ds-color--blue__light,
            $ds-color--blue__light
        );
    }

    &:active {
        @include btnBackround(
            $ds-color--blue,
            $ds-color--white,
            $ds-color--blue
        );
    }
}

/* Seconday Borderless */
.ds-button--cat__secondary.ds-button--background__borderless {
    @include btnBackround($ds-color--blue, $ds-color--white, $ds-color--white);
    box-shadow: none;

    &:hover,
    &:focus {
        @include btnBackround(
            $ds-color--white,
            $ds-color--blue__light,
            $ds-color--blue__light
        );
    }

    &:active {
        @include btnBackround(
            $ds-color--white,
            $ds-color--blue,
            $ds-color--blue
        );
    }
}

/* Success Ghost */
.ds-button--cat__success.ds-button--background__ghost {
    @include btnBackround(
        $ds-color-utility--green__dark,
        $ds-color--white,
        $ds-color-utility--green__dark
    );

    &:hover,
    &:focus {
        @include btnBackround(
            $ds-color--white,
            $ds-color-utility--green,
            $ds-color-utility--green
        );
    }

    &:active {
        @include btnBackround(
            $ds-color-utility--green__dark,
            $ds-color--white,
            $ds-color-utility--green__dark
        );
    }
}

/* Cancel Borderless */
.ds-button--cat__cancel.ds-button--background__borderless {
    @include btnBackround($ds-color--black, $ds-color--white, $ds-color--white);
    box-shadow: none;

    &:hover,
    &:focus {
        @include btnBackround(
            $ds-color--white,
            $ds-color--gray,
            $ds-color--gray
        );
    }

    &:active {
        @include btnBackround(
            $ds-color--white,
            $ds-color--gray__lighter,
            $ds-color--gray__lighter
        );
    }
}

/* Disabled Ghost */
.ds-button--cat__disabled.ds-button--background__ghost {
    @include btnBackround(
        $ds-color--gray__lighter,
        $ds-color--white,
        $ds-color--gray__lighter
    );
    box-shadow: none;
}

/* Disabled Borderless */
.ds-button--cat__disabled.ds-button--background__borderless {
    @include btnBackround(
        $ds-color--gray__lighter,
        $ds-color--white,
        $ds-color--white
    );
    box-shadow: none;
}
