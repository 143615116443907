// Durations (Do not touch)
$ds-motion-duration--shortest: 75ms;
$ds-motion-duration--shorter: 100ms;
$ds-motion-duration--short: 150ms;
$ds-motion-duration--medium: 200ms;
$ds-motion-duration--long: 250ms;
$ds-motion-duration--longer: 300ms;
$ds-motion-duration--longest: 500ms;

// Easing
//// For on-screen motion
$ds-motion-easing--standard: cubic-bezier(0.4, 0, 0.2, 1);
//// For elements leaving the screen
$ds-motion-easing--accelerate: cubic-bezier(0, 0, 0.2, 1);
//// For elements ariving on the screen
$ds-motion-easing--decelerate: cubic-bezier(0.4, 0, 1, 1);

// Selection
//// For selecting on page elements: checkboxs, switches, radios...
$ds-motion-selection: all $ds-motion-duration--short $ds-motion-easing--standard;

// Fade
//// When elements appear on the screen with a fade: Modals, dialogs, alerts...
$ds-motion-fade--in: all $ds-motion-duration--short
    $ds-motion-easing--decelerate;

//// When elements disapear from the screen with a fade: Modals, dialogs, alerts...
$ds-motion-fade--out: all $ds-motion-duration--shortest
    $ds-motion-easing--accelerate;

// Movement
//// When elements move from off-screen to on-sceen
$ds-motion-arrive: all $ds-motion-duration--long $ds-motion-easing--decelerate;

//// When elements move from on-screen to off-sceen
$ds-motion-depart: all $ds-motion-duration--medium $ds-motion-easing--accelerate;
