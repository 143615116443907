@import "../../../../styles/variables";

.loan-information--list {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: flex-start;

    li {
        font-weight: bold;
        margin-bottom: 0.35vh;
        list-style: none;
        margin-left: 0.5em;

        &:before {
            content: "|";
            margin-right: 0.5rem;
        }
        &:first-child {
            margin-left: 0;

            &:before {
                content: none;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
