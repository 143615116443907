/* 
TABLE - <table>
*/

.ds-table {
    border-collapse: collapse;
    padding: auto 2rem;

    caption {
        padding: ($ds-spacing-x-small - 0.1rem) ($ds-spacing-large - 0.2rem);
        @include typo--roboto__medium;
        text-align: left;
        color: $ds-color--white;
        font-size: $ds-font-size--small;
        background-color: $ds-color--blue;
    }

    caption::first-letter {
        text-transform: uppercase;
    }

    thead {
        border-bottom: $ds-border-width-thin solid $ds-color--gray__lighter;
        td,
        th {
            padding: $ds-spacing-small $ds-spacing-x-small;
            @include typo--roboto__bold;
            font-size: $ds-font-size--small;
            text-align: left;
            color: $ds-color--gray__darker;
        }
    }

    tbody {
        tr {
            border-bottom: $ds-border-width-thin solid $ds-color--gray__lighter;
            @include typo--roboto__regular;
        }

        td {
            padding: $ds-spacing-small $ds-spacing-x-small;

            font-size: $ds-font-size--small;
            text-align: left;
        }

        td > button,
        td > button:hover {
            box-shadow: none;
        }

        td > button.ds-button--size__small {
            margin: -0.6rem 0;
        }
    }

    &.ds-table--skeleton tbody td {
        &:before {
            width: 100%;
            content: "\00a0 ";
            display: inline-block;
            border-radius: $ds-spacing-x-small;
            font-size: $ds-spacing-x-small;
            color: $ds-color--gray__lighter;
            background-color: $ds-color--gray__lighter;
        }
        &.no-skeleton:before {
            content: "";
        }
    }
}

.ds-table--selectable {
    tbody {
        tr {
            background-color: white;
            &:hover {
                color: $ds-color--blue;
                background-color: $ds-color--gray__xx-light;
                transition: $ds-motion-selection;
                cursor: pointer;
            }
        }
        td > button {
            cursor: pointer;
        }
    }
}

.ds-table--indented {
    margin: 0 $ds-spacing-large;

    caption {
        margin-left: -($ds-spacing-large);
        margin-right: -($ds-spacing-large);
    }

    thead {
        th:first-child,
        th:first-child {
            padding: $ds-spacing-small $ds-spacing-small $ds-spacing-small 0;
        }

        th:last-child,
        td:last-child {
            padding: $ds-spacing-small 0 $ds-spacing-small $ds-spacing-small;
        }
    }

    tbody {
        td:first-child {
            padding: $ds-spacing-small $ds-spacing-x-small $ds-spacing-small 0;
        }

        td:last-child {
            padding: $ds-spacing-small 0 $ds-spacing-small $ds-spacing-x-small;
        }
    }
}
