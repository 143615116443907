@import "../../../styles/variables";

.upload-success-message {
    .upload-success__text {
        margin-bottom: $ds-spacing-small;
    }

    .upload-success__document {
        margin: $ds-spacing-x-small 0;
        .document__label {
            font-size: $ds-font-size--base;
            font-weight: bold;
            color: $ds-color--black;
        }
    }
    .upload-success__controls {
        display: flex;
        justify-content: flex-end;
        padding-top: $ds-spacing-x-small;
    }
}

@media only screen and (min-width: 1535px) {
    .upload-success-message {
        min-width: 500px;
    }
}
