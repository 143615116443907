@import "../../../styles/variables";

.amc-404--container {
    text-align: center;
    min-height: calc(100vh - 106px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        color: $ds-color--blue;
        font-size: $ds-font-size--largest * 2;
        margin: 0;
    }
    p {
        font-size: 2.15rem;
        color: $ds-color--black;
    }
    a {
        padding-top: 1rem;
        color: $ds-color--blue;
        font-size: 2.5rem;
    }
}
