@import "../../../styles/variables";

.upload-error-message {
    .upload-error__text {
        max-width: 400px;
        margin-bottom: $ds-spacing-small;
    }

    .upload-error__controls {
        display: flex;
        justify-content: flex-end;
        padding-top: $ds-spacing-x-small;
    }
}
