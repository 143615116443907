.amc-document--download {
    max-width: 10rem;
    padding: 0.25rem 1rem;
    background-color: #fff;
    border: 1px solid #000;

    &:hover {
        cursor: pointer;
    }
}
