/* 
INPUT <input>
*/

.ds-input {
    display: block;
    width: 100%;
    padding: $ds-spacing-small;
    text-indent: $ds-spacing-small;
    border: $ds-border-width-thin solid $ds-color--gray__lighter;
    border-radius: $ds-border-radius-medium;
    box-sizing: border-box;
    @include typo--roboto__medium;
    font-size: $ds-font-size--base;
    line-height: $ds-line-height;
    color: $ds-color--gray__darker;
    appearance: none;

    &:hover {
        border-color: $ds-color--gray;
    }

    &:focus {
        box-shadow: 0 0 1px 1px rgba(59, 153, 252, 0.7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        outline: none;
    }
}

.ds-input {
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        @include typo--roboto__regular;
        color: $ds-color--gray__darker;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        @include typo--roboto__regular;
        color: $ds-color--gray__darker;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        @include typo--roboto__regular;
        color: $ds-color--gray__darker;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        @include typo--roboto__regular;
        color: $ds-color--gray__darker;
    }
}

//
// INPUT SIZE
//
@mixin inputSize($fontSize) {
    font-size: $fontSize;
    padding: ($fontSize * 0.286)+0.1 0;
}
/* Why this padding?  The goal is to make button and input heights the same.  
Inputs have 1px (or 0.1 rem) borders.  Buttons have 2px (or 0.2rem) borders. 
To make heights the same inputs need the same padding plus and addition 0.1 
for the difference in borders   */

// Large
.ds-input--size__large {
    @include inputSize($ds-font-size--larger);
}

// Medium
.ds-input--size__medium {
    @include inputSize($ds-font-size--base);
}

// Small
.ds-input--size__small {
    @include inputSize($ds-font-size--small);
}

// Smallest
.ds-input--size__smallest {
    @include inputSize($ds-font-size--small);
}
