/* 
CONTROL BUTTON <button>
*/

.ds-control-button {
    width: 33px;
    height: 33px;
    @include typo--roboto__bold;
    line-height: 1;
    letter-spacing: normal;
    outline: none;
    border-style: solid;
    border-radius: 50%;
    box-sizing: border-box;
    appearance: none;

    &:hover,
    &:focus {
        box-shadow: $ds-box-shadow--gray__hover-focus;
    }

    &:active {
        box-shadow: $ds-box-shadow--gray__active;
    }
}

/* CATEGORY */
@mixin btnCategory($text, $background, $border) {
    background-color: $background;
    color: $text;
    border-color: $border;
    transition: $ds-motion-selection;
}

/* Primary */
.ds-control-button--cat__primary {
    @include btnCategory(
        $ds-color--white,
        $ds-color--blue__dark,
        $ds-color--blue__dark
    );

    &:hover,
    &:focus {
        @include btnCategory(
            $ds-color--white,
            $ds-color--blue,
            $ds-color--blue
        );
    }

    &:active {
        @include btnCategory(
            $ds-color--white,
            $ds-color--blue__dark,
            $ds-color--blue__dark
        );
    }
}

/* Secondary */
.ds-control-button--cat__secondary {
    @include btnCategory($ds-color--white, $ds-color--blue, $ds-color--blue);

    &:hover,
    &:focus {
        @include btnCategory(
            $ds-color--white,
            $ds-color--blue__light,
            $ds-color--blue__light
        );
    }

    &:active {
        @include btnCategory(
            $ds-color--white,
            $ds-color--blue,
            $ds-color--blue
        );
    }
}
