@import "../../../styles/variables";

.modal-overlay {
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
}

.modal {
    position: relative;
    max-width: 800px;
    margin: $ds-spacing-large auto 0 auto;
    background-color: $ds-color--white;
    border-bottom-left-radius: $ds-border-radius-medium;
    border-bottom-right-radius: $ds-border-radius-medium;
    background-clip: padding-box;
    box-shadow: $ds-box-shadow-size__active 0 rgba(0, 0, 0, 0.1);

    .modal--title {
        font-weight: bold;
        padding: $ds-spacing-x-small $ds-spacing-large;
        color: $ds-color--white;
        background-color: $ds-color--gray__darker;
    }

    .modal--body {
        padding: $ds-spacing-small $ds-spacing-large;
        border: 1px solid $ds-color--black;
        border-bottom-left-radius: $ds-border-radius-medium;
        border-bottom-right-radius: $ds-border-radius-medium;
    }

    &.modal--type-primary {
        .modal--title {
            background-color: $ds-color--blue;
        }
    }

    &.modal--type-success {
        .modal--title {
            background-color: $ds-color-utility--green;
        }
    }

    &.modal--type-warning {
        .modal--title {
            background-color: $ds-color-utility--yellow;
        }
    }

    &.modal--type-error {
        .modal--title {
            background-color: $ds-color-utility--red__dark;
        }
    }
}

.modal--close {
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    svg {
        width: 1.8rem;
        height: 1.8rem;
    }
    /*Visually hide close text */
    span {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
}

.modal--footer-controls {
    display: block;
}

.button {
    margin: 0;
    padding: 0.35rem;
    border: 1px solid rgb(161, 161, 161);
    background-color: rgb(239, 239, 239);
}
