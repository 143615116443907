@import "../../../styles/variables";

.amc-user {
    border: 0;
    padding: 0.5rem 0 0.5rem 3rem;
    border-radius: 1rem;
    outline: none;
    display: flex;
    align-items: center;

    span {
        margin-right: 1rem;
    }
}
