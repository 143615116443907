/* 
TYPOGRAPHY 
*/

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    line-height: $ds-line-height;
    @include typo--roboto__medium;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $ds-color--black;
}

p {
    @include typo--roboto__medium;
    color: $ds-color--black;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
}

a {
    color: $ds-color--blue;

    &:hover,
    &:focus {
        color: $ds-color--blue__light;
        transition: $ds-motion-selection;
    }

    &:active {
        color: $ds-color--blue__dark;
        transition: $ds-motion-selection;
    }

    &:visited {
        color: $ds-color--orange__dark;
    }
}

.ds-section-heading {
    min-height: 32px;
    margin: 0;
    padding: $ds-spacing-xx-small $ds-spacing-large;
    font-size: $ds-font-size--base;
    font-weight: bold;
    color: $ds-color--white;
    background-color: $ds-color--blue;
}
