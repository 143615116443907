// Typography Sizes
$ds-font-size--largest: 2.5rem;
$ds-font-size--larger: 2rem;
$ds-font-size--large: 1.8rem;
$ds-font-size--base: 1.6rem;
$ds-font-size--small: 1.4rem;
$ds-line-height: 1.5;

// Roboto
@mixin typo--roboto__light {
    font-family: roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
}

@mixin typo--roboto__regular {
    font-family: roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
}

@mixin typo--roboto__medium {
    font-family: roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
}

@mixin typo--roboto__bold {
    font-family: roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
}

// Roboto Mono
@mixin typo--roboto-mono__light {
    font-family: roboto-mono, monospace;
    font-style: normal;
    font-weight: 300;
}

@mixin typo--roboto-mono__regular {
    font-family: roboto-mono, monospace;
    font-style: normal;
    font-weight: 400;
}

@mixin typo--roboto-mono__medium {
    font-family: roboto-mono, monospace;
    font-style: normal;
    font-weight: 500;
}

@mixin typo--roboto-mono__bold {
    font-family: roboto-mono, monospace;
    font-style: normal;
    font-weight: 700;
}

// Futura
@mixin typo--futura__medium {
    font-family: futura-pt, sans-serif;
    font-style: normal;
    font-weight: 500;
}

@mixin typo--futura__heavy {
    font-family: futura-pt, sans-serif;
    font-style: normal;
    font-weight: 700;
}

@mixin typo--futura__bold {
    font-family: futura-pt-bold, sans-serif;
    font-style: normal;
    font-weight: 700;
}
