/* 
SELECT - <select>
*/

.ds-select {
    display: block;
    width: 100%;
    margin-top: $ds-spacing-xxx-small;
    margin-bottom: $ds-spacing-xxx-small;
    padding-inline-start: 0;
    padding: $ds-spacing-small;
    text-indent: $ds-spacing-small;
    border: $ds-border-width-thin solid $ds-color--gray__lighter;
    border-radius: $ds-border-radius-medium;
    box-sizing: border-box;
    @include typo--roboto__medium;
    font-size: $ds-font-size--base;
    line-height: $ds-line-height;
    color: $ds-color--gray__darker;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg%20data-name%3D%22down%20arrow%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%228%22%20viewBox%3D%220%200%2010%208%22%3E%3Cpolyline%20points%3D%2210%200%200%200%205%208%22%20style%3D%22fill%3A%23717272%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    appearance: none;

    &:hover {
        border-color: $ds-color--gray;
    }

    &:focus {
        box-shadow: 0 0 1px 1px rgba(59, 153, 252, 0.7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        outline: none;
    }

    &:disabled,
    [aria-disabled="true"] {
        color: $ds-color--gray;
    }

    &:disabled:hover,
    &[aria-disabled="true"] {
        background-color: $ds-color--gray__lighter;
    }

    option {
        @include typo--roboto__regular;
        color: $ds-color--gray__darker;
    }
}

/* Large */
.ds-select--size__large {
    @include inputSize($ds-font-size--larger);
}

/* Medium */
.ds-select--size__medium {
    @include inputSize($ds-font-size--base);
}

/* Small */
.ds-select--size__small {
    @include inputSize($ds-font-size--small);
}

/* Smallest */
.ds-select--size__smallest {
    @include inputSize($ds-font-size--small);
}
