@import "../../styles/variables";

.amc-header {
    display: flex;
    align-items: center;
    min-height: 5.3rem;
    padding: 0.5rem $ds-font-size--larger;
    line-height: 1;
    color: $ds-color--blue;

    > div {
        flex: 1 1 auto;
    }
    .amc-header--brand {
        padding: 0.5rem 0;
        font-size: $ds-font-size--larger;
        font-weight: bold;

        .amc-logo {
            justify-content: flex-start;
            align-items: center;

            img {
                width: 100px;
            }
        }
    }

    .amc-header--login {
        margin-left: auto;
        display: block;
    }

    .amc-header--user {
        margin-left: auto;

        .amc-user {
            justify-content: flex-end;

            span {
                color: #1a4f7d;
                font-weight: bold;
            }
        }
        .hidden {
            display: none;
        }

        .show {
            display: inline;
        }
    }
}
