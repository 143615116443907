@import "../../../../styles/variables";

.pdf-viewer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom-left-radius: $ds-border-radius-medium;
    border-bottom-right-radius: $ds-border-radius-medium;
    background-color: $ds-color--gray__lighter;

    .pdf-viewer--document {
        height: 100%;
        border-top: $ds-border-width-thin solid $ds-color--gray__lighter;
        border-bottom: $ds-border-width-thin solid $ds-color--gray__lighter;
        overflow: auto;

        .react-pdf {
            &__Document {
                display: flex;
                flex-direction: column;
            }

            &__Page {
                box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
                border: 1px solid transparent;
                margin: 1.5rem auto;

                canvas {
                    height: auto !important;
                }

                &.highlight {
                    box-shadow: 0 0 5px $ds-color--blue;
                    border: 1px solid $ds-color--blue;
                }
            }

            &__Page__textContent {
                overflow: hidden;
            }

            &__message {
                padding: 20px;
                color: white;
            }
        }
    }
}
