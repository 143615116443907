@import "../../../../../styles/variables";

.pdf-viewer-error {
    text-align: center;
    color: $ds-color-utility--red;
    padding: $ds-spacing-large;

    button {
        margin: $ds-spacing-large;
    }
}
