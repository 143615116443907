@import "../../styles/variables";

.amc-loading-indicator {
    position: relative;

    /* Label for accessiblity.  Hide visually */
    .amc-loading-indicator__label {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }

    .amc-loading-indicator__node {
        border-width: 3px;
        border-style: solid;

        width: $ds-spacing-x-small;
        height: $ds-spacing-x-small;
        display: inline-block;
        border-radius: $ds-border-radius-circle;
        margin-left: $ds-spacing-xxx-small;
        margin-right: $ds-spacing-xxx-small;
        opacity: 0;
    }

    .amc-loading-indicator__node:nth-child(1) {
        animation-duration: 2000ms;
        animation-name: colorChange1;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        border-color: $ds-color--blue;
        animation-delay: 0ms;
    }

    .amc-loading-indicator__node:nth-child(2) {
        animation-duration: 2000ms;
        animation-name: colorChange2;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        border-color: $ds-color--blue;
        animation-delay: 500ms;
    }

    .amc-loading-indicator__node:nth-child(3) {
        animation-duration: 2000ms;
        animation-name: colorChange3;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        border-color: $ds-color--blue;
        animation-delay: 1000ms;
    }

    @keyframes colorChange1 {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes colorChange2 {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
    @keyframes colorChange3 {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
}
