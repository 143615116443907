/* 
FORM <form>
*/

.ds-form {
    border-radius: $ds-border-radius-medium;
    padding-top: $ds-spacing-base;
    padding-left: $ds-spacing-base;
    padding-right: $ds-spacing-base;
}

.ds-form-group {
    margin-bottom: $ds-spacing-base;
    clear: both;
}
