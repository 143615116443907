@import "../../styles/variables";

.amc-footer {
    display: flex;
    padding: $ds-spacing-base $ds-spacing-large;
    @include typo--roboto__regular;
    font-size: $ds-font-size--small;
    .amc-footer__copyright {
        margin-left: auto;
    }
}
