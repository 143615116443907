@import "../../styles/variables";

$topBottomSpacing: $ds-spacing-base;
$sideSpacing: $ds-spacing-large;
$additionalWidth: ($sideSpacing * 2);

h1.amc-main-heading {
    width: calc(100% + #{$additionalWidth});
    min-height: 5.7rem;
    margin: 0 #{-$sideSpacing} $topBottomSpacing;
    padding: $topBottomSpacing $sideSpacing;
    line-height: 1;
    @include typo--futura__heavy;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $ds-color--white;
    background-color: $ds-color--blue__dark;
}
