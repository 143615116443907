/* 
Labels <label>
*/

.ds-label {
    color: $ds-color--black;
    font-size: $ds-font-size--base;
    display: block;
    text-indent: $ds-spacing-small;
}

/* Label Size */

/* Large */
.ds-label--size__large {
    font-size: $ds-font-size--larger;
}

/* Medium */
.ds-label--size__medium {
    font-size: $ds-font-size--base;
}

/* Small */
.ds-label--size__small {
    font-size: $ds-font-size--small;
}

/* Smallest */
.ds-label--size__smallest {
    font-size: $ds-font-size--small;
}
