@import "../../../styles/variables";

/* $loan-body-height is a combination of the heights of header, main heading, and loan controls */
$loan-body-height: 204px;

.loan-controls {
    height: 60px;
}

.loan-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
}

.loan-body__left {
    position: relative;
    flex: 0 1 auto;
    align-self: auto;
    margin-right: $ds-spacing-large;
    margin-bottom: $ds-spacing-large;
    width: 100%;
}

.loan-body__right {
    position: relative;
    flex: 0 1 auto;
    align-self: auto;
    width: 100%;
    min-height: 100vh;
}

.loan-loading {
    color: $ds-color--blue__light;
}

.loan-error {
    text-align: center;
    color: $ds-color-utility--red;
    .loan-error__message {
        margin-bottom: $ds-spacing-large;
    }
    .loan-error__controls button {
        margin-right: $ds-spacing-x-small;
    }
}

@media screen and (min-width: 990px) {
    .loan-body {
        flex-direction: row;
        height: calc(100vh - #{$loan-body-height});
    }

    .loan-body__left {
        margin-bottom: 0;
        width: calc(40% - #{$ds-spacing-large});
    }

    .loan-body__right {
        width: 60%;
        min-height: auto;
    }
}

@media screen and (min-width: 1280px) {
    .loan-body__left {
        width: calc(45% - #{$ds-spacing-large});
    }

    .loan-body__right {
        width: 55%;
    }
}

@media screen and (min-width: 1440px) {
    .loan-body__left {
        width: calc(50% - #{$ds-spacing-large});
    }

    .loan-body__right {
        width: 50%;
    }
}

@media screen and (min-width: 1900px) {
    .loan-body__left {
        flex: 0 1 900px;
    }

    .loan-body__right {
        flex: 1 1 auto;
    }
}
