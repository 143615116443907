@import "../../styles/variables";

.upload-document {
    display: flex;
    margin-top: $ds-spacing-x-small;
    padding-right: $ds-spacing-large;
    padding-bottom: $ds-spacing-x-small;

    .upload-document__file-input {
        display: none;
    }

    .upload-document__button {
        margin-left: auto;
    }
}
