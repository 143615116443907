@import "../../../../styles/variables";

/* $document-list-wrapper-max-height is calculated by subtracting the sum of all other elements from the total viewport height. */
$document-list-wrapper-max-height: 294px;

.document-list__wrapper {
    position: relative;
    max-height: calc(100vh - #{$document-list-wrapper-max-height});
    overflow-y: auto;

    .document-list__not-viewable {
        margin: $ds-spacing-large;
        text-align: center;
    }

    .document-list__not-viewable--loading {
        color: $ds-color--blue__light;
    }

    .document-list__not-viewable--error {
        color: $ds-color-utility--red;
    }

    .document-list__retry-button {
        margin-top: $ds-spacing-large;
    }
}
