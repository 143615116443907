@import "../../../../styles/variables";

/* .ds-table--indented has $ds-spacing-large on each size*/
$indent: $ds-spacing-large * 2;

.search-results-table {
    table.ds-table {
        /* width of table 100% minus the margin added by indentataion */
        width: calc(100% - #{$indent});

        tbody tr.focused,
        tbody tr:hover {
            background-color: $ds-color--gray__xx-light;
        }

        tbody tr:last-child {
            border-bottom: 0;
        }

        th,
        .column--firstname,
        .column--lastname,
        .column--address,
        .column--city {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 0; /* Avoid resizing beyond table width */
            min-width: 12rem;
        }

        .column--open {
            min-width: 70px;
            text-align: right;
        }

        &.ds-table--skeleton {
            tbody tr.focused,
            tbody tr:hover {
                background-color: white;
            }

            .column--firstname,
            .column--lastname,
            .column--city {
                width: 15%;
            }

            .column--address {
                width: 20%;
            }

            .column--state {
                width: 5%;
            }

            .column--loan-number,
            .column--zip {
                width: 10%;
            }
        }
    }
}

@media only screen and (min-width: 1535px) {
    .search-results-table {
        table.ds-table {
            .column--lastname,
            .column--address,
            .column--city {
                white-space: normal;
                overflow: visible;
                text-overflow: clip;
                max-width: initial;
                min-width: initial;
            }
        }
    }
}
