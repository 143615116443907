/* 
INPUT GROUP
*/

.ds-input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    margin-bottom: $ds-spacing-x-small;
}

/* hide labels since they aren't used on input groups */
.ds-input-group .ds-label {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
}

/* Remove top and bottom margins, the input-group will take care of them */
.ds-input-group .ds-input,
.ds-input-group .ds-button {
    margin-top: 0;
    margin-bottom: 0;
}

.ds-input-group > .ds-input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.ds-input-group-prepend,
.ds-input-group-append {
    display: flex;
}

.ds-input-group-prepend .ds-button,
.ds-input-group-append .ds-button {
    position: relative;
    z-index: 2;
}

/* Remove rounded borders from the correct elements */
.ds-input-group > .ds-input:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ds-input-group > .ds-input:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ds-input-group > .ds-input-group-prepend > .ds-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ds-input-group > .ds-input-group-append > .ds-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
