@import "../../../../../styles/variables";

.pdf-viewer--controls {
    display: flex;
    justify-content: flex-end;
    min-height: 49px;
    align-items: center;
    padding: $ds-spacing-x-small $ds-spacing-base;
    background-color: $ds-color--white;

    > button {
        margin-left: $ds-spacing-xx-small;
    }

    > .action-group {
        margin: 0 $ds-spacing-x-small;

        > button {
            margin-right: $ds-spacing-xx-small;
        }
    }
}
