/* 
WRAPPER - adds a rounded border with a box shaddow
*/

.ds-wrapper {
    margin: 0 3px 6px 3px;
    padding-bottom: $ds-spacing-xxx-small;
    border-radius: $ds-border-radius-medium;
    box-shadow: $ds-box-shadow--gray__native;
    background-color: $ds-color--white;

    &.ds-wrapper--flat {
        border-radius: 0;
        border: 1px solid $ds-color--gray__lighter;
        border-top: 0;
        box-shadow: none;
    }
}
