/////////////
//  Blues  //
/////////////

// Blue Dark - Primary
// #1a4f7d;
$ds-color--blue__dark: rgb(26, 79, 125);

// Blue - Secondary
// #006199;
$ds-color--blue: rgb(0, 97, 153);

// Blue Light - Tertiary
// #4787b3;
$ds-color--blue__light: rgb(71, 135, 179);

// Blue Lighter
// #8daccc;
$ds-color--blue__lighter: rgb(141, 172, 204);

// Blue Lightest
// #eaf7ff;
$ds-color--blue__lightest: rgb(234, 247, 255);

//////////////////////
//  UTILITY COLORS  //
//////////////////////

//////////////////////
//  UTILITY GREEN   //
//////////////////////

// Green Dark
// #008048;
$ds-color-utility--green__dark: rgb(0, 128, 72);

// Green
// #00964b;
$ds-color-utility--green: rgb(0, 150, 75);

$ds-color-utility--green__highlight: rgba(0, 150, 75, 0.15);

////////////////////
//  UTILITY RED   //
////////////////////

// Red Dark
// #c31c17;
$ds-color-utility--red__dark: rgb(195, 28, 23);

// Red
// #e01a1a;
$ds-color-utility--red: rgb(224, 26, 26);

// Red Light
//: #e84e4e;
$ds-color-utility--red__light: rgb(232, 78, 78);

$ds-color-utility--red__hightlight: rgba(224, 26, 26, 0.15);

///////////////////////
//  UTILITY YELLOW   //
///////////////////////

// Yellow Dark
//: #f8b62f;
$ds-color-utility--yellow__dark: rgb(248, 182, 47);

// Yellow
// #fcd05f;
$ds-color-utility--yellow: rgb(252, 208, 95);

$ds-color-utility--yellow__highlight: rgba (252, 208, 95, 0.2);

//////////////
//  SHADES  //
//////////////

// Black
// #4d4d4d;
$ds-color--black: rgb(77, 77, 77);

// Gray Darker
// #717171;
$ds-color--gray__darker: rgb(113, 113, 113);

// Gray
// #a1a1a1;
$ds-color--gray: rgb(161, 161, 161);

// Gray
// #d0d0d0;
$ds-color--gray__lighter: rgb(208, 208, 208);

// Gray x-light
// #e8e8e8
$ds-color--gray__x-light: rgb(232, 232, 232);

// Gray x-light
// #f8f8f8;
$ds-color--gray__xx-light: rgb(248, 248, 248);

// White
// #ffffff;
$ds-color--white: rgb(255, 255, 255);

////////////////////////////////////////////////////////////
// ACCENT COLORS - Do not use these to build components.  //
////////////////////////////////////////////////////////////

//////////////
//  Orange  //
//////////////

// Orange Darkest
// #3c1c0e;
$ds-color--orange__darkest: rgb(60, 28, 14);

// Orange Darker
//#79381c;
$ds-color--orange__darker: rgb(121, 56, 28);

// Orange Dark
// #b55329;
$ds-color--orange__dark: rgb(181, 83, 41);

// Orange
//: #f16f37;
$ds-color--orange: rgb(241, 111, 55);

// Orange Light
// #f59369;
$ds-color--orange__light: rgb(245, 147, 105);

// Orange Lighter
// #f8b79b;
$ds-color--orange__lighter: rgb(248, 183, 155);

// Orange Lightest
// #fbdbcd;
$ds-color--orange__lightest: rgb(251, 219, 205);

////////////
//  Teal  //
////////////

// Teal Darkest
// #092324;
$ds-color--teal__darkest: rgb(9, 35, 36);

// Teal Darker
// #124547;
$ds-color--teal__darker: rgb(18, 69, 71);

// Teal Dark
//: #1b676a;
$ds-color--teal__dark: rgb(27, 103, 106);

// Teal
// #248a8e;
$ds-color--teal: rgb(36, 138, 142);

// Teal Light
// #5ba7aa;
$ds-color--teal__light: rgb(91, 167, 170);

// Teal Lighter
// #92c5c7;
$ds-color--teal__lighter: rgb(146, 197, 199);

// Teal Lightest
// #c8e2e3;
$ds-color--teal__lightest: rgb(200, 226, 227);

/////////////
//  Green  //
/////////////

// Green Darkest
// #29341f;
$ds-color--green__darkest: rgb(41, 52, 31);

// Green Darker
// #52683d;
$ds-color--green__darker: rgb(82, 104, 61);

// Green Dark
// #7b9b5b;
$ds-color--green__dark: rgb(123, 155, 91);

// Green
// #a4cf7a;
$ds-color--green: rgb(164, 207, 122);

// Green Light
// #bbdb9b;
$ds-color--green__light: rgb(187, 219, 155);

// Green Lighter
// #d2e7bd;
$ds-color--green__lighter: rgb(210, 231, 189);

// Green Lightest
// #e8f3de;
$ds-color--green__lightest: rgb(232, 243, 222);
