@import "variables";
@import "mixins/mixins";

/* RESET: Post CSS Normalize from Create React App */
@import-normalize;

/* Typography has some app specific html tag resets. */
@import "typography";

@import "elements";

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.amc-main {
    padding: 0 $ds-spacing-large 0 $ds-spacing-large;
}

.screen-reader-only {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}
