@import "../../../../styles/variables";

.search-input {
    position: relative;
    max-width: 400px;
    min-width: 100px;

    .search-input--spinner {
        position: absolute;
        top: $ds-spacing-xx-small;
        right: $ds-spacing-xx-small;
    }
}
