.uds-alert-scoped {
    @include typo--roboto__regular;
    border-width: $ds-border-width-thick;
    border-style: solid;
    margin-bottom: $ds-spacing-large;
    box-shadow: $ds-box-shadow--gray__native;
    border-radius: $ds-border-radius-medium;
    margin: $ds-spacing-base;
}

.uds-alert-scoped--alert-icon {
    display: inline;
}

.uds-alert-scoped--alert-header-title {
    display: inline;
}

.uds-alert-content-container {
    padding: $ds-spacing-base;
}
.uds-alert-scoped-header__error,
.uds-alert-scoped-header__warning,
.uds-alert-scoped-header__success {
    @include typo--roboto__bold;
}

.uds-alert-scoped-header__error {
    padding: $ds-spacing-base;
    color: $ds-color--white;
    background-color: $ds-color-utility--red__dark;
    font-size: $ds-font-size--larger;
}

.uds-alert-scoped-header__warning {
    padding: $ds-spacing-base;
    color: $ds-color--black;
    background-color: $ds-color-utility--yellow__dark;
    font-size: $ds-font-size--larger;
}

.uds-alert-scoped-header__success {
    padding: $ds-spacing-base;
    color: $ds-color--white;
    background-color: $ds-color-utility--green__dark;
    font-size: $ds-font-size--larger;
}

.uds-alert-scoped--alert-header-title {
    font-size: $ds-font-size--larger;
}

.uds-alert-scoped__error {
    background: $ds-color--white;
    border-color: $ds-color-utility--red__dark;
}

.uds-alert-scoped__warning {
    background: $ds-color--white;
    border-color: $ds-color-utility--yellow__dark;
}

.uds-alert-scoped__success {
    background: $ds-color--white;
    border-color: $ds-color-utility--green__dark;
}

.uds-alert-scoped-message {
    //margin-bottom: $ds-spacing-none;
}

.uds-alert-scoped-message__warning {
    color: $ds-color--black;
}

.uds-alert-scoped-message__success {
    color: $ds-color--black;
}

.uds-alert-scoped-message__error {
    color: $ds-color--black;
}
