@import "../../../../styles/variables";

.search-results {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .search-results__status {
        min-height: 2.4rem;
        margin-left: $ds-spacing-xx-small;
    }

    .search-results__error {
        font-size: $ds-font-size--small;
        color: $ds-color-utility--red;
        margin-bottom: $ds-spacing-small;
    }

    .search-results__not-found {
        font-size: $ds-font-size--small;
        color: $ds-color-utility--red;
    }

    .search-results__query-value {
        font-style: italic;
    }
}
